import "./TestingConcepts.css";

export const TestingConceptsPage = () => {
	return (
		<>
			<div>
  {/* <h1>Header 1a</h1> */}
  <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
  <div>
    <h2>Header 2a</h2>
    <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
    <div>
      <h3>Header 3a</h3>
      <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
      <h3>Header 3b</h3>
      <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
    </div>
  </div>
  <div>
    <h2>Header 2b</h2>
    <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
    <div>
      <h3>Header 3c</h3>
      <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
      <h3>Header 3d</h3>
      <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
    </div>
  </div>
</div>
<div>
  {/* <h1>Header 1b</h1> */}
  <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
  <div>
    <h2>Header 2c</h2>
    <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
    <div>
      <h3>Header 3e</h3>
      <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
      <h3>Header 3f</h3>
      <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
    </div>
  </div>
  <div>
    <h2>Header 2d</h2>
    <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
    <div>
      <h3>Header 3g</h3>
      <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
      <h3>Header 3h</h3>
      <p>Lorem ipsum dolor sit amet, no qui quis eloquentiam.</p>
    </div>
  </div>
</div>
		</>
	)
}